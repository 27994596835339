import axios from 'src/utils/axios';
import authService from 'src/services/authService';
import accountService from '../services/accountService';

export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const LOGIN_FAILURE = '@account/login-failure';
export const SILENT_LOGIN = '@account/silent-login';
export const LOGOUT = '@account/logout';
export const REGISTER = '@account/register';
export const REGISTER_FAILURE = '@account/register-failure';
export const REGISTRATION_COMPLETE = '@account/register-complete';
export const REGISTRATION_COMPLETE_FAILURE = '@account/register-complete-failure';
export const UPDATE_PROFILE = '@account/update-profile';
export const UPDATE_PROFILE_FAILURE = '@account/update-profile-error';
export const ACCOUNT_LOCATE_FAILURE = '@account/locate-account-failure';
export const ACCOUNT_LOCATED = '@account/locate-account';
export const ACCOUNT_NOTIFICATION_SENT = '@account/account-notification-sent';
export const ACCOUNT_LOCATED_BY_ID = '@account/account-locate-by-id';


export function login(email, password) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOGIN_REQUEST });

      const user = await authService.loginWithEmailAndPassword(email, password);

      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user
        }
      });
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function setUserData(user) {
  return (dispatch) => dispatch({
    type: SILENT_LOGIN,
    payload: {
      user
    }
  });
}

export function logout() {
  return async (dispatch) => {
    authService.logout();

    dispatch({
      type: LOGOUT
    });
  };
}

export function locateAccount(data) {
  return async (dispatch) => {
    try {
      const result = await authService.locateAccount(data);
      dispatch({
        type: ACCOUNT_LOCATED
      });
      return result;
    } catch (error) {
      dispatch({ type: ACCOUNT_LOCATE_FAILURE });
      throw error;
    }
  };
}

export function locateAccountByInvoiceAndLastName(data) {
  return async (dispatch) => {
    try {
      const result = await authService.locateAccountByInvoiceAndLastName(data);
      dispatch({
        type: ACCOUNT_LOCATED
      });
      return result;
    } catch (error) {
      dispatch({ type: ACCOUNT_LOCATE_FAILURE });
      throw error;
    }
  };
}

export function sendVerificationCodeById(id, method) {
  return async (dispatch) => {
    try {
      const result = await authService.sendVerificationCodeById(id, method);
      dispatch({
        type: REGISTER
      });
      return result;
    } catch (error) {
      dispatch({ type: ACCOUNT_LOCATE_FAILURE });
      throw error;
    }
  };
}

export function locateAccountById(data) {
  return async (dispatch) => {
    try {
      const result = await authService.locateAccountById(data);
      dispatch({
        type: ACCOUNT_LOCATED
      });
      return result;
    } catch (error) {
      dispatch({ type: ACCOUNT_LOCATE_FAILURE });
      throw error;
    }
  };
}

export function register(to, method) {
  return async (dispatch) => {
    try {
      const result = await authService.sendVerificationCode(to, method);
      dispatch({
        type: REGISTER
      });
      return result;
    } catch (error) {
      dispatch({ type: REGISTER_FAILURE });
      throw error;
    }
  };
}

export function registerComplete(to, method, verify, userId) {
  return async (dispatch) => {
    try {
      const result = await authService.validateVerificationCode(to, method, verify, userId);
      dispatch({
        type: REGISTRATION_COMPLETE
      });
    } catch (error) {
      dispatch({ type: REGISTRATION_COMPLETE_FAILURE });
      throw error;
    }
  };
}

export function updateProfile(update) {

  return async (dispatch) => {
    try {
      const result = await accountService.updateProfile(update);
      dispatch({
        type: UPDATE_PROFILE,
        payload: result.data
      });
    } catch (error) {
      dispatch({ type: UPDATE_PROFILE_FAILURE });
      throw error;
    }
  };

}

export function resetPassword(update) {

  return async (dispatch) => {
    try {
      const result = await accountService.resetPassword(update);
      dispatch({
        type: UPDATE_PROFILE,
        payload: result.data
      });
    } catch (error) {
      dispatch({ type: UPDATE_PROFILE_FAILURE });
      throw error;
    }
  };

}

