import axios from 'axios';
import jwtDecode from 'jwt-decode';

const api = process.env.REACT_APP_RETAIL_CLAIMS_PORTAL || 'https://api.nationsafedrivers.com/nsd-api-consumer-portal/v1';

class AuthService {
  setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          this.setSession(null);

          if (onLogout) {
            onLogout();
          }
        }

        return Promise.reject(error);
      }
    );
  };

  loginWithEmailAndPassword = (email, password) => new Promise((resolve, reject) => {
    axios.post(`${api}/login`, { email, password }, {
      headers: { 'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_NSD_API_KEY}` }
    })
      .then((response) => {
        if (response.data.user) {
          this.setSession(response.data.accessToken);
          console.log("Login Token", response.data.accessToken)
          resolve(response.data.user);
        } else {
          console.log("Rejected", response.data.message)
          reject(response.data.message);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });

  sendResetPasswordEmail = (email, option) => new Promise((resolve, reject) => {
    axios.post(`${api}/forgot-password`, {email, option}, {
      headers: { 'Ocp-Apim-Subscription-Key': 'f005ba91859a4e2fba39f2432acea1ef' }
    })
      .then((response) => {
        if (response.data.success) {
          localStorage.setItem('resetToken', response.data.accesstoken);
          resolve(response);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });

  sendResetPasswordPhone = (phone, option) => new Promise((resolve, reject) => {
    axios.post(`${api}/forgot-password`, {phone, option}, {
      headers: { 'Ocp-Apim-Subscription-Key': 'f005ba91859a4e2fba39f2432acea1ef' }
    })
      .then((response) => {
        if (response.data.success) {
          localStorage.setItem('resetToken', response.data.accesstoken);
          resolve(response);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });

  logout = () => {
    this.setSession(null);
  };

  setSession = (accessToken) => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem('accessToken');
      delete axios.defaults.headers.common.Authorization;
    }
  };

  getAccessToken = () => localStorage.getItem('accessToken');

  isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  };

  isAuthenticated = () => !!this.getAccessToken();

  sendVerificationCode = (to, method) => new Promise((resolve, reject) => {
    console.log({ to: to, method: method });
    axios.get(`${api}/users?sendnotificationId=${to}&method=${method}`, {
      headers: { 'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_NSD_API_KEY}` }
    })
    .then((response) => {
      if (response.data.user) {
        this.setSession(response.data.accessToken);
        resolve(response.data.user);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      reject(error);
    });
  });

  locateAccount = (data) => new Promise((resolve, reject) => {
    axios.get(`${api}/users?accountSearchOption=emailAndPhone&lastName=${data.lastName}&mobilePhone=${data.mobilePhone}&emailAddress=${data.email}`, {
      headers: { 'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_NSD_API_KEY}` }
    })
      .then((response) => {
        if (response.data.users) {
          this.setSession(response.data.accessToken);
          resolve(response.data.users);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });

  locateAccountByInvoiceAndLastName = (data) => new Promise((resolve, reject) => {
    axios.get(`${api}/users?accountSearchOption=invoiceNumber&invoiceNumber=${data.invoiceNumber}&lastName=${data.lastName}`, {
      headers: { 'Ocp-Apim-Subscription-Key': 'f005ba91859a4e2fba39f2432acea1ef' }
    })
      .then((response) => {                                                                                                 
        if (response.data.users) {
          this.setSession(response.data.accessToken);
          resolve(response.data.users);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });

  locateAccountById = (id) => new Promise((resolve, reject) => {
    axios.get(`${api}/users?id=${id}`, {
      headers: { 'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_NSD_API_KEY}` }
    })
      .then((response) => {
        if (response.data.user) {
          this.setSession(response.data.accessToken);
          resolve(response.data.user);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });

  sendVerificationCodeById = (id, method) => new Promise((resolve, reject) => {
    axios.get(`${api}/users?sendnotificationId=${id}&method=${method}`, {
      headers: { 'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_NSD_API_KEY}` }
    })
      .then((response) => {
        if (response.data.user) {
          this.setSession(response.data.accessToken);
          resolve(response.data.user);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });

  handleAuthentication() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return;
    }

    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken);
    } else {
      this.setSession(null);
    }
  }

  loginInWithToken = () => new Promise((resolve, reject) => {
    axios.get(`${api}/account/me`, {
      // axios.get('http://localhost:7071/api/account/me', {
      headers: { 'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_NSD_API_KEY}` }
    })
      .then((response) => {
        if (response.data.user) {
          this.setSession(response.data.accessToken);
          resolve(response.data.user);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });

  validateVerificationCode = (to, method, verify, userId) => new Promise((resolve, reject) => {
    axios.post(`${api}/registration`, {
        verifyTo: to,
        verifyCode: verify,
        userId,
        method
      },
      { headers: { 'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_NSD_API_KEY}` } }
    )
      .then((response) => {
        if (response.data.msg === 'approved') {
          this.setSession(response.data.accessToken);
          localStorage.setItem('accessToken', response.data.accessToken);
          resolve(response.data);
        } else {
          reject(response.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });

}

const authService = new AuthService();

export default authService;